<template>
    <div class="invoice-address uf uf-column">
        <div>
            <el-form :inline="true" :model="dataForm">
<!--                <el-form-item>-->
<!--                    <el-input v-model="dataForm.name" placeholder="收票人姓名" clearable></el-input>-->
<!--                </el-form-item>-->
                <el-form-item>
<!--                    <el-button @click="getAddressList()">查询</el-button>-->
                    <el-button type="primary" @click="addAddress()">新增</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="uf-f1" style="overflow:auto;">
            <el-table
               :data="dataList"
               border
               height="100%"
               v-loading="dataListLoading"
               style="width: 100%;">
                <el-table-column type="index" label="序号" header-align="center" align="center" width="80" :index="indexMethod"></el-table-column>
                <el-table-column label="收货人" prop="receiveName" header-align="center" align="center"></el-table-column>
                <el-table-column label="电话" prop="mobile" header-align="center" align="center"></el-table-column>
                <el-table-column label="所在地区" header-align="center" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.provinceCode+ '/' + scope.row.cityCode + '/' + scope.row.countyCode}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="详细地址" prop="address" header-align="center" align="center"></el-table-column>
                <el-table-column label="是否默认" prop="isDefault" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.isDefault === 1">是</el-tag>
                        <el-tag type="danger" v-else>否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" prop="address" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="addAddress(scope.row)">修改</el-button>
                        <el-button type="text" size="small" @click="deleteAddress(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="uf uf-ac uf-pe mt-2">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
<!--        新增收票地址-->
        <addAddress v-if="addAddressVisible" ref="addAddressRef" @closeCallBack="closeCallBack"></addAddress>
    </div>
</template>

<script>
    import addAddress from './components/add-address'
    export default {
       name: 'invoice-address',
        components: {
            addAddress
        },
        data () {
           return {
               dataForm: {
                   name: '',
                   id: ''
               },
               dataList: [],
               dataListLoading: false,
           //    分页参数
               pageIndex: 1,
               pageSize: 10,
               totalCount: 0,
           //    新增收票地址弹框显隐
               addAddressVisible: false
           }
        },
        created () {
           this.getAddressList()
        },
        methods: {
        //   获取收票地址列表
            getAddressList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.ADDRESS_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.dataList = page.list || []
                        this.totalCount = page.totalCount || 0
                    }
                })
            },
            // 添加收票地址
            addAddress (item = {}) {
                this.addAddressVisible = true
                this.$nextTick(() => {
                    this.$refs.addAddressRef.init(item)
                })
            },
            // 删除收票地址
            deleteAddress (item) {
                this.$confirm('确定要删除该收票地址吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$http.adornUrl(this.$api.INVOICE.DELETE_ADDRESS),
                        method: 'POST',
                        data: this.$http.adornData([item.id], false)
                    }).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            })
                            this.getAddressList(true)
                        }
                    })
                }).catch(() => {
                })
            },
            // 新增 弹框关闭回调
            closeCallBack (isRefresh = false) {
                this.addAddressVisible = false
                if (isRefresh) {
                    this.getAddressList(true)
                }
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getAddressList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getAddressList()
            },
            indexMethod (index) {
                return (this.pageIndex - 1) * this.pageSize + index + 1
            }
        }
    }
</script>

<style scoped>
    .invoice-address {
        height: calc(100vh - 220px);
    }

</style>