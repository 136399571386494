<template>
    <div class="add-address">
        <el-dialog
                :title="isAdd ? '新增收货地址' : '修改收货地址'"
                :visible.sync="dialogVisible"
                width="600px"
                :before-close="handleClose">
            <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="收货人" prop="receiveName">
                    <el-input v-model="dataForm.receiveName" placeholder="请输入收货人"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile">
                    <el-input v-model="dataForm.mobile" placeholder="请输入电话"></el-input>
                </el-form-item>
                <el-form-item label="所在地区" v-if="areaUpdate">
                    <div class="uf uf-ac uf-pj" >
                        <el-input v-model="area"  clearable  disabled></el-input>
                        <div class="ml-2">
                            <el-button type="text" size="small" @click="areaUpdate = !areaUpdate" v-if="!isAdd">修改</el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="所在地区" prop="area" v-else>
                    <el-cascader
                            class="wi100"
                            :options="options"
                            v-model="dataForm.area"
                            @change="handleChange">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address" >
                    <el-input v-model="dataForm.address" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"
                              placeholder="请输入详细地址" maxlength="200" clearable></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" v-model="dataForm.remark" placeholder="请输入备注" clearable></el-input>
                </el-form-item>
                <el-form-item prop="remark">
                    <el-checkbox v-model="dataForm.isDefault" :true-label="1" :false-label="0">设为默认地址</el-checkbox>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
             <el-button @click="handleClose">取 消</el-button>
             <el-button type="primary" @click="onSubmit" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {regionData, CodeToText} from 'element-china-area-data'
    export default {
       name: 'add-address',
        data () {
           return {
               dialogVisible: false,
               dataForm: {
                   receiveName: '',
                   mobile: '',
                   provinceCode: '',
                   cityCode: '',
                   countyCode: '',
                   address: '',
                   remark: '',
                   isDefault: 0,
                   area: []
               },
               rules: {
                   receiveName: [
                       { required: true, message: '请输入收货人', trigger: 'blur' }
                   ],
                   mobile: [
                       { required: true, message: '请输入收货人电话', trigger: 'blur' }
                   ],
                   area: [
                       { required: true, message: '请选择联系人地址', trigger: 'change' }
                   ],
                   address: [
                       { required: true, message: '请输入详细地址', trigger: 'blur' }
                   ]
               },
               options: regionData,
               selectedOptions: [],
               address: '',
               isAdd: true,
               area: '',
               areaUpdate: false,
               loading: false
           }
        },
        created () {
        },
        methods: {
        //   初始化
            init (item) {
                this.dialogVisible = true
                if (item && item.id) {
                    this.dataForm = this.$deepClone(item)
                    this.isAdd = false
                    this.areaUpdate = true
                    this.area = item.provinceCode+ '/' + item.cityCode + '/' + item.countyCode
                }
            },
            handleChange(val) {
                if (val) {
                    var loc = []
                    for (let i = 0; i < this.dataForm.area.length; i++) {
                        loc.push(CodeToText[this.dataForm.area[i]])
                    }
                    this.dataForm.provinceCode = loc[0] || ''
                    this.dataForm.cityCode = loc[1] || ''
                    this.dataForm.countyCode = loc[2] || ''
                }
            },
        //    提交
            onSubmit () {
              this.$refs.ruleForm.validate((valid) => {
                  if (valid) {
                      this.loading = true
                      let data = this.$deepClone(this.dataForm)
                      this.$http({
                          url: this.$http.adornUrl(this.isAdd? this.$api.INVOICE.ADD_ADDRESS : this.$api.INVOICE.UPDATE_ADDRESS),
                          method: 'POST',
                          data: this.$http.adornData({
                              ...data
                          })
                      }).then(({data}) => {
                          if (data && data.code === 0) {
                              this.loading = false
                              this.$message.success('保存成功')
                              this.dialogVisible = false
                              this.$emit('closeCallBack', true)
                          } else {
                              this.loading = false
                          }
                      })
                  }
              })
            },
        //   关闭
            handleClose() {
                this.dialogVisible = false
                this.$emit('closeCallBack')
            }
        }
    }
</script>

<style scoped>

</style>